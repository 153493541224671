import { AuthService } from '@/common/services/auth/auth.service';
import { ObjectValues } from '@/common/types/common.types';

export const SLOTS = {
  Left: 'Left',
  DocumentList: 'DocumentList',
  Export: 'Export',
  Access: 'Access',
  MainContent: 'MainContent',
  CasePilot: 'CasePilot',
  Right: 'Right',
} as const;
export type SlotId = ObjectValues<typeof SLOTS>;

export interface PanelMetadata {
  slot: SlotId;
  initialState: boolean;
  detachable: boolean;
  enabled: (authService: AuthService) => boolean;
}

// if not button — not presented in nav rail
export interface PanelMetadataWithButton extends PanelMetadata {
  button: {
    divider: (authService: AuthService) => boolean;
    onClick: () => void;
    icon: string;
    closeTooltipMessageId: string;
    openTooltipMessageId: string;
    subdued: boolean; // admin features (legali-admin, tenant-admin)
  };
}

export const panels = {
  // not visible in navigation
  DuplicatesReview: {
    slot: SLOTS.MainContent,
    initialState: false,
    detachable: false,
    enabled: () => true,
  },
  WebViewer: {
    slot: SLOTS.MainContent,
    initialState: true,
    detachable: false,
    enabled: () => true,
  },

  CasePilot: {
    slot: SLOTS.CasePilot,
    initialState: false,
    detachable: true,
    enabled: (authService) => authService.hasFeature('ENABLE_MEDINSIGHTS'),
  },

  // CASE
  // CasePilot button here on top (inserted manually in navrail)
  LegalCaseAccess: {
    slot: SLOTS.Access,
    initialState: false,
    detachable: false,
    enabled: (authService) =>
      authService.hasPermission('ACCESS_CASE_DETAIL_LEGALCASE_ACCESS') && authService.hasFeature('ENABLE_CASE_DETAIL_COLLABORATION'),
    // LegalCaseAccess button inserted manually (a bit special, needs badge with counter)
  },
  PII: {
    slot: SLOTS.Right,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('LEGALI_ADMIN'),
    button: {
      divider: () => false,
      onClick: () => {},
      icon: 'mdi-face-man-shimmer',
      closeTooltipMessageId: 'CaseDetail.Pii.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Pii.openTooltip',
      subdued: true,
    },
  },

  // DOCS
  Labels: {
    slot: SLOTS.Left,
    initialState: false,
    detachable: false,
    enabled: () => true,
    button: {
      divider: () => true,
      onClick: () => {},
      icon: 'mdi-label',
      closeTooltipMessageId: 'CaseDetail.Labels.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Labels.openTooltip',
      subdued: false,
    },
  },
  DocumentList: {
    slot: SLOTS.DocumentList,
    initialState: true,
    detachable: false,
    enabled: () => true,
    button: {
      divider: () => false,
      onClick: () => {},
      icon: 'mdi-timeline-outline',
      closeTooltipMessageId: 'CaseDetail.DocumentsList.closeTooltip',
      openTooltipMessageId: 'CaseDetail.DocumentsList.openTooltip',
      subdued: false,
    },
  },
  Copilot: {
    slot: SLOTS.Right,
    initialState: false,
    detachable: true,
    enabled: (authService) => authService.hasFeature('ENABLE_CASE_DETAIL_DOCUMENT_COPILOT'),
    button: {
      divider: () => false,
      onClick: () => {},
      icon: 'mdi-creation',
      closeTooltipMessageId: 'CaseDetail.CopilotPanel.closeTooltip',
      openTooltipMessageId: 'CaseDetail.CopilotPanel.openTooltip',
      subdued: false,
    },
  },
  Authors: {
    slot: SLOTS.Left,
    initialState: false,
    detachable: false,
    enabled: () => true,
    button: {
      divider: () => false,
      onClick: () => {},
      icon: 'mdi-account-outline',
      closeTooltipMessageId: 'CaseDetail.InvolvedParties.closeTooltip',
      openTooltipMessageId: 'CaseDetail.InvolvedParties.openTooltip',
      subdued: false,
    },
  },
  Metadata: {
    slot: SLOTS.Right,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('ACCESS_CASE_DETAIL_METADATA'),
    button: {
      divider: () => false,
      onClick: () => {},
      icon: 'mdi-pipe',
      closeTooltipMessageId: 'CaseDetail.Metadata.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Metadata.openTooltip',
      subdued: true,
    },
  },
  FormData: {
    slot: SLOTS.Right,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('LEGALI_ADMIN'),
    button: {
      divider: () => false,
      onClick: () => {},
      icon: 'mdi-form-textbox',
      closeTooltipMessageId: 'CaseDetail.FormData.closeTooltip',
      openTooltipMessageId: 'CaseDetail.FormData.openTooltip',
      subdued: true,
    },
  },

  // EXTRACTION
  Diagnosis: {
    slot: SLOTS.Left,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasFeature('ENABLE_CASE_DETAIL_MEDICAL'),
    button: {
      divider: () => true,
      onClick: () => {},
      icon: 'mdi-stethoscope',
      closeTooltipMessageId: 'CaseDetail.Diagnoses.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Diagnoses.openTooltip',
      subdued: false,
    },
  },
  WorkInabilities: {
    slot: SLOTS.Left,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasFeature('ENABLE_CASE_DETAIL_MEDICAL'),
    button: {
      divider: () => false,
      onClick: () => {},
      icon: 'mdi-wheelchair',
      closeTooltipMessageId: 'CaseDetail.WorkInabilities.closeTooltip',
      openTooltipMessageId: 'CaseDetail.WorkInabilities.openTooltip',
      subdued: false,
    },
  },
  Timelines: {
    slot: SLOTS.Left,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('LEGALI_ADMIN'),
    button: {
      divider: () => false,
      onClick: () => {},
      icon: 'mdi-clock-outline',
      closeTooltipMessageId: 'CaseDetail.Timelines.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Timelines.openTooltip',
      subdued: false,
    },
  },

  // TOOLS
  Notebook: {
    slot: SLOTS.Right,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('ACCESS_CASE_DETAIL_NOTEBOOK'),
    button: {
      divider: () => true,
      onClick: () => {},
      icon: 'mdi-notebook-outline',
      closeTooltipMessageId: 'CaseDetail.Notebook.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Notebook.openTooltip',
      subdued: false,
    },
  },
  Collaboration: {
    slot: SLOTS.Right,
    initialState: false,
    detachable: false,
    enabled: (authService) =>
      authService.hasPermission('ACCESS_CASE_DETAIL_COLLABORATION') && authService.hasFeature('ENABLE_CASE_DETAIL_COLLABORATION'),
    button: {
      divider: (authService: AuthService) => !authService.hasPermission('ACCESS_CASE_DETAIL_NOTEBOOK'),
      onClick: () => {},
      icon: 'mdi-account-group-outline',
      closeTooltipMessageId: 'Tickets.closeTooltip',
      openTooltipMessageId: 'Tickets.openTooltip',
      subdued: false,
    },
  },
  Export: {
    slot: SLOTS.Export,
    initialState: false,
    detachable: false,
    enabled: (authService) => authService.hasPermission('ACCESS_CASE_DETAIL_EXPORT') && authService.hasFeature('ENABLE_CASE_DETAIL_EXPORT'),
    button: {
      divider: (authService: AuthService) =>
        !authService.hasPermission('ACCESS_CASE_DETAIL_NOTEBOOK') &&
        !authService.hasPermission('ACCESS_CASE_DETAIL_COLLABORATION') &&
        !authService.hasFeature('ENABLE_CASE_DETAIL_COLLABORATION'),
      onClick: () => {},
      icon: 'mdi-file-export-outline',
      closeTooltipMessageId: 'CaseDetail.Export.closeTooltip',
      openTooltipMessageId: 'CaseDetail.Export.openTooltip',
      subdued: false,
    },
  },
} satisfies Record<string, PanelMetadata | PanelMetadataWithButton>;

export type PanelId = keyof typeof panels;
