import { mergeWith } from 'lodash';
import { reactive, Ref, ref, watch } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { $t } from '@/app/i18n/i18n.service';
import { UserPreferences } from '@/common/generated-types/openapi';
import { mergeIntoReactive } from '@/common/services/common.utils';
import { $api } from '@/common/services/openapi.client.service';

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

class PreferencesService {
  public state: UserPreferences;

  public loaded: Ref<boolean>;

  constructor() {
    this.loaded = ref(false);
    this.state = reactive({} as UserPreferences);
  }

  public async loadPreferences() {
    const preferences = (await this.fetchPreferences()) as UserPreferences;
    this.loaded.value = true;
    mergeIntoReactive(this.state, preferences);
  }

  runAfterLoad(fn: () => void) {
    if (this.loaded.value) {
      fn();
      return;
    }

    const unwatch = watch(this.loaded, (to) => {
      if (to) {
        fn();
        unwatch();
      }
    });
  }

  private async fetchPreferences() {
    try {
      const client = await $api('userPreferencesClient');
      const response = await client.getUserPreferences();
      return response.data;
    } catch (error) {
      handleError($t('Common.Preferences.loadError'), error as Error);
      return {};
    }
  }

  public async updatePreferences(updateProperty: DeepPartial<UserPreferences>) {
    function customizer(objValue: any, srcValue: any) {
      if (Array.isArray(objValue)) {
        // rewrite array values to prevent unexpected merges and be able to cleanup
        return srcValue;
      }
      return undefined;
    }
    mergeWith(this.state, updateProperty, customizer);
    await this.savePreferences();
  }

  public async savePreferences() {
    try {
      const client = await $api('userPreferencesClient');
      await client.updateUserPreferences(null, this.state);
    } catch (error) {
      handleError($t('Common.Preferences.updateError'), error as Error);
    }
  }
}

export default new PreferencesService();
export const PreferencesServiceClass = PreferencesService;
